<template>
  <div class="col-md-7 mx-auto">
    <div class="card shadow-lg rounded">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>Servicio de correo electrónico</h1>
        </div>
        <br>
        <form v-on:submit.prevent="saveItem">
          <div class="form-group">
            <label>Conexión (*):</label>
            <textarea rows="4" class="form-control" v-model="item.ema_connection" required />
          </div>
          <div class="form-group">
            <label>Correo electrónico (*):</label>
            <input type="text" v-model="item.ema_email" class="form-control">
          </div>
          <div class="form-group">
            <label>Usuario (*):</label>
            <input type="text" v-model="item.ema_user" class="form-control">
          </div>
          <div class="form-group">
            <label>Contraseña (*):</label>
            <input v-if="add" type="password" class="form-control" v-model="item.ema_pass" required>
            <button v-if="!add" type="button" class="btn btn-secondary form-control" v-on:click.prevent="newPwd()" data-toggle="modal" data-target="#pwdModal">Cambiar contraseña</button>
            <pwdchange v-on:change-pwd="pwdChange(pwd)" v-bind:item="pwd"></pwdchange>              
          </div>
          <div class="text-center">
            <p class="msg">{{ message }}</p>
          </div>
          <div class="row text-center">
            <div class="col-md-12">
              <button type="submit" class="btn btn-primary">Guardar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  //import toastr from 'toastr';
  import PwdChange from './PwdChange.vue';

  export default {
    components: {
      'pwdchange': PwdChange
    },
    data(){
      return{
        add: true,
        item: {},
        message: '',
        ready: false,
        pwd: {}
      }
    },
    created: function() {
      this.getItem();
    },
    methods: {
      saveItem(){      
        if (this.add){
          let uri = '/emails/add';
          this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
          this.axios.post(uri, this.item)
          .then(response => {
            this.add = false;
            this.message = 'Información guardada.';
          })
          .catch(err => {
            this.message = 'Error al guardar la información.';
          });
        } else {
          let uri = '/emails/update';
          this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
          this.axios.post(uri, this.item)
          .then((response) => {
            this.message = 'Información guardada.';
          })
          .catch((err) => {
            this.message = 'Error al guardar la información.';
          });
        }
      },
      getItem(){
        let uri = '/emails/edit';
        this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri)
        .then((response) => {
          if (response.data){
            this.add = false;
            this.item = response.data;
          } else {
            this.add = true;
          }
        });
      },
      newPwd(){
        this.pwd = { ema_connection: this.item.ema_connection, ema_email: this.item.ema_email, ema_user: this.item.ema_user }
      },
      pwdChange(p){
        if (p.ema_pass_new != p.ema_pass_conf){
          alert('No se ha confirmado la nueva contraseña. Verifique los datos y vuelva a intentarlo.')
          return;
        }
        let uri = '/emails/securityadmin';
        this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, { _id: this.item._id, ema_pass_new: p.ema_pass_new }).then((response) => {
          alert('La contraseña fue cambiada satisfactoriamente.');
          //this.$router.replace({ name: 'Profile' });
        }).catch((err) => {
          alert('No se ha podido cambiar la contraseña. Verifique los datos y vuelva a intentarlo.');
        });
      }      
    }
  }
  </script>

  <style>
    .msg {
      color: #ff0000;
      background-color: white;
    }
  </style>
